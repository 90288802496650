import { AxiosInstance, AxiosResponse } from 'axios';

import { Department } from '../../models/department.model';
import ColisportApiResource from './colisport-api-resource.model';

export default class DepartmentApi implements ColisportApiResource {
  readonly apiUrlPath = 'department';

  constructor(readonly httpClient: AxiosInstance) {}

  getDepartmentsByName(name: string): Promise<AxiosResponse<Department[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/name/${name}`);
  }

  getDepartmentsWithFilters(filter: string): Promise<AxiosResponse<Department[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/find/${filter}`);
  }
}
