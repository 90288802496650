import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import { Department } from '../../models/department.model';

export default class InternationalCountriesApi implements ColisportApiResource {
  readonly apiUrlPath = 'internationalPostalCodes';

  constructor(readonly httpClient: AxiosInstance) {}

  getAllCountries(): Promise<AxiosResponse<string[]>> {
    return this.httpClient.get(`${this.apiUrlPath}`);
  }

  getDepartmentsWithFilters(
    countryCode: string,
    filter: string,
  ): Promise<AxiosResponse<Department[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/find/${countryCode}/${filter}`);
  }

  getCountryCode(country: string): Promise<AxiosResponse<string>> {
    return this.httpClient.get(`${this.apiUrlPath}/country/${country}`);
  }

  getAll(): Promise<AxiosResponse<{ countryName: string; countryCode: string }[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/list`);
  }

  createSingleCollection(
    countryName: string,
    countryCode: string,
  ): Promise<AxiosResponse<{ countryName: string; countryCode: string }[]>> {
    return this.httpClient.post(`${this.apiUrlPath}`, { countryName, countryCode });
  }

  deleteCountry(
    countryCode: string,
  ): Promise<AxiosResponse<{ countryName: string; countryCode: string }[]>> {
    return this.httpClient.delete(`${this.apiUrlPath}/${countryCode}`);
  }
}
