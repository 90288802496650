import ContactAddressChoice from 'models/agency-choice.model';
import { ColiSportSteps } from 'models/global';
import { DeliveryPrice } from 'models/offer-option.model';
import React, { useMemo, useReducer } from 'react';
import { DeliveryDetailForm } from '../components/DeliveryDetail/DeliveryDetailForm.interface';
import { DeliveryDetail } from '../models/delivery-detail-form.model';
import { PaymentChoice } from '../models/payment-choice.model';
import { RecapChoice } from '../models/recap-choice.model';
import RevolutOrderDto from '../models/revolut-order-dto.model';
import { StepsFormState, actions, initialState, stepsFormReducer } from '../state/form/reducers';

export type StepsFormContextType = {
  steps: ColiSportSteps;
  maxStepReached: number;
  trackingNumber: string;
  colisportID: string;
  requestID: string;
  requestAccountType: string;
  contactAddressChoice: ContactAddressChoice;
  paymentChoice: PaymentChoice;
  order: RevolutOrderDto;
  recapChoice: RecapChoice;
  // TODO: This needs to be removed from here, there is no need for this type os state
  repeatOrder: DeliveryDetail;
  offerChange: boolean;
  validityCheck: boolean;
  updateSteps: (steps: Partial<ColiSportSteps>) => void;
  updateMaxStepsReached: (maxStepReached: number) => void;
  updateContactAddressChoice: (contactAddressChoice: Partial<ContactAddressChoice>) => void;
  offerOptions: DeliveryPrice[];
  updateOfferOptions: (offerOptions: DeliveryPrice[]) => void;
  updateTrackingNumber: (trackingNumber: string) => void;
  updateColisportID: (colisportID: string) => void;
  updateRequestID: (requestID: string) => void;
  updateRequestAccountType: (requestAccountType: string) => void;
  updateOrder: (order: RevolutOrderDto) => void;
  updatePaymentChoice: (paymentChoice: Partial<PaymentChoice>) => void;
  updateRecapChoice: (recapChoice: RecapChoice) => void;
  updateValidityCheck: (validityCheck: boolean) => void;
  updateOfferChange: (offerChange: boolean) => void;
  onRepeatOrder: (repeatOrder: DeliveryDetailForm) => void;
  // TODO: This reset form should be refactored
  resetForm: (initialState?: Partial<StepsFormState>) => void;
};

const defaultFormContextValues: StepsFormContextType = {
  steps: initialState.steps,
  maxStepReached: initialState.maxStepReached,
  contactAddressChoice: initialState.contactAddressChoice,
  trackingNumber: initialState.trackingNumber,
  colisportID: initialState.colisportID,
  requestID: initialState.requestID,
  requestAccountType: initialState.requestAccountType,
  order: initialState.order,
  paymentChoice: initialState.paymentChoice,
  recapChoice: initialState.recapChoice,
  repeatOrder: initialState.repeatOrder,
  validityCheck: initialState.validityCheck,
  offerChange: initialState.offerChange,
  updateSteps: () => {},
  updateMaxStepsReached: () => {},
  updateContactAddressChoice: () => {},
  offerOptions: [],
  updateOfferOptions: () => {},
  updateTrackingNumber: () => {},
  updateColisportID: () => {},
  updateRequestID: () => {},
  updateRequestAccountType: () => {},
  updateOrder: () => {},
  updatePaymentChoice: () => {},
  updateRecapChoice: () => {},
  updateValidityCheck: () => {},
  updateOfferChange: () => {},
  onRepeatOrder: () => {},
  resetForm: () => {},
};

export const StepsFormContext = React.createContext<StepsFormContextType>(defaultFormContextValues);

const StepsFormProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(stepsFormReducer, initialState);

  const value: StepsFormContextType = useMemo(
    () => ({
      steps: state.steps,
      maxStepReached: state.maxStepReached,
      offerOptions: state.offerOptions,
      contactAddressChoice: state.contactAddressChoice,
      trackingNumber: state.trackingNumber,
      colisportID: state.colisportID,
      requestID: state.requestID,
      requestAccountType: state.requestAccountType,
      order: state.order,
      paymentChoice: state.paymentChoice,
      recapChoice: state.recapChoice,
      repeatOrder: state.repeatOrder,
      validityCheck: state.validityCheck,
      offerChange: state.offerChange,
      updateSteps: (steps: Partial<ColiSportSteps>) => {
        dispatch({ type: actions.UPDATE_STEPS, payload: steps });
      },
      updateMaxStepsReached: (maxStepReached: number) => {
        dispatch({ type: actions.UPDATE_MAX_STEPS_REACHED, payload: maxStepReached });
      },
      updateContactAddressChoice: (contactAddressChoice: Partial<ContactAddressChoice>) => {
        dispatch({ type: actions.UPDATE_CONTACT_ADDRESS_CHOICE, payload: contactAddressChoice });
      },
      updateOfferOptions: (offerOptions: DeliveryPrice[]) => {
        dispatch({ type: actions.UPDATE_OFFER_OPTIONS, payload: offerOptions });
      },
      updateTrackingNumber: (trackingNumber: string) => {
        dispatch({ type: actions.UPDATE_TRACKING_NUMBER, payload: trackingNumber });
      },
      updateColisportID: (colisportID: string) => {
        dispatch({ type: actions.UPDATE_COLISPORT_ID, payload: colisportID });
      },
      updateRequestID: (requestID: string) => {
        dispatch({ type: actions.UPDATE_REQUEST_ID, payload: requestID });
      },
      updateRequestAccountType: (requestAccountType: string) => {
        dispatch({ type: actions.UPDATE_REQUEST_ACCOUNT_TYPE, payload: requestAccountType });
      },
      updateOrder: (order: RevolutOrderDto) => {
        dispatch({ type: actions.UPDATE_ORDER, payload: order });
      },
      updateRecapChoice: (recapChoice: RecapChoice) => {
        dispatch({ type: actions.UPDATE_RECAP_CHOICE, payload: recapChoice });
      },
      updateValidityCheck: (validityCheck: boolean) => {
        dispatch({ type: actions.UPDATE_VALIDITY_CHECK, payload: validityCheck });
      },
      updateOfferChange: (offerChange: boolean) => {
        dispatch({ type: actions.UPDATE_OFFER_CHANGE, payload: offerChange });
      },
      updatePaymentChoice: (paymentChoice: PaymentChoice) => {
        dispatch({ type: actions.UPDATE_PAYMENT_CHOICE, payload: paymentChoice });
      },
      onRepeatOrder: (repeatOrder: DeliveryDetailForm) => {
        dispatch({ type: actions.ON_REPEAT_ORDER, payload: repeatOrder });
      },
      resetForm: (formState?: StepsFormState) => {
        dispatch({ type: actions.RESET_FORM, payload: formState });
      },
    }),
    [state],
  );

  return <StepsFormContext.Provider value={value}>{children}</StepsFormContext.Provider>;
};

export default StepsFormProvider;
