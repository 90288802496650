import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import OnewayAgency from '../../models/oneway-agency.model';
import { DeliveryPrice } from '../../models/offer-option.model';
import { OneWayRequest } from '../../models/oneway-offer-request.model';
import { FilterShipment } from '../../models/filter-shipment.model';
import { UploadCroquiRequest } from '../../models/upload-croqui.request';
import UserType from '../../models/UserType.model';

export default class OneWayApi implements ColisportApiResource {
  readonly apiUrlPath = 'oneway';

  constructor(readonly httpClient: AxiosInstance) {}

  async getAgencies(): Promise<AxiosResponse<OnewayAgency[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/agencies`);
  }

  async getOffers(data: OneWayRequest): Promise<AxiosResponse<DeliveryPrice[]>> {
    return this.httpClient.post(`${this.apiUrlPath}/offers`, data);
  }

  async getShipments(query?: string): Promise<AxiosResponse<FilterShipment>> {
    return this.httpClient.get(`${this.apiUrlPath}/shipments${query !== '' ? `?${query}` : ''}`, {
      params: `shipments?${query}`,
    });
  }

  async uploadCroqui(data: UploadCroquiRequest): Promise<AxiosResponse<any>> {
    return this.httpClient.post(`${this.apiUrlPath}/upload/croqui`, data);
  }

  async downloadCroqui(
    colisportID: string,
    index: number,
    croquiType: 'check-in' | 'check-out',
  ): Promise<AxiosResponse<ArrayBuffer>> {
    return this.httpClient.get(
      `${this.apiUrlPath}/download/croqui/${colisportID}/${index}/${croquiType}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async validatePackages(
    colisportID: string,
    onewayStoreType: UserType,
  ): Promise<AxiosResponse<void>> {
    return this.httpClient.put(
      `${this.apiUrlPath}/validate-packages/${colisportID}/${onewayStoreType}`,
    );
  }

  async validateOrderDeliveredToUser(colisportID: string): Promise<AxiosResponse<void>> {
    return this.httpClient.put(
      `${this.apiUrlPath}/validate-order-delivered-to-user/${colisportID}`,
    );
  }
}
