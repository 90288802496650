import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';

export default class HealthApi implements ColisportApiResource {
  readonly apiUrlPath = 'health';

  constructor(readonly httpClient: AxiosInstance) {}

  getEnvironment(): Promise<AxiosResponse<string>> {
    return this.httpClient.get(`${this.apiUrlPath}/environment`);
  }
}
