import DataAccountDetails from 'models/data-account-details.model';
import { UserProfile } from 'models/user-profile.model';
import React, { useMemo, useReducer } from 'react';
import { navigate } from 'gatsby';
import ErrorHelper from '../helpers/ErrorHelper';
import {
  getToken,
  getUser,
  logout,
  setToken,
  setUser,
} from '../services/api/auth-session-storage.service';
import { createToken } from '../services/api/auth.service';
import { getUserProfile, updateProfileDetails } from '../services/api/user.service';
import { actions, initialState, userReducer } from '../state/user/reducers';

export type UserContextType = {
  profile: UserProfile;
  resetLoggedUser: () => void;
  updateAuthToken: (token: string) => void;
  signInUser: (email: string, password: string) => Promise<void>;
  isUserLoggedIn: boolean;
  updateProfileDetails: (email: string, userDetails: DataAccountDetails) => Promise<void>;
  hasUserChosenGuest: boolean;
  updateHasUserChosenGuest: (hasUserChosenGuest: boolean) => void;
  authToken: string;
};

const defaultUserContextValues: UserContextType = {
  profile: undefined,
  resetLoggedUser: () => {},
  updateAuthToken: () => {},
  signInUser: () => Promise.resolve(),
  isUserLoggedIn: false,
  updateProfileDetails: () => Promise.resolve(),
  hasUserChosenGuest: false,
  updateHasUserChosenGuest: () => {},
  authToken: '',
};

export const UserContext = React.createContext<UserContextType>(defaultUserContextValues);

const UserProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const signIn = async (email: string, password: string) => {
    try {
      await createToken(email, password);
      const resp = await getUserProfile();
      dispatch({ type: actions.UPDATE_USER_PROFILE, payload: resp.data });
      setUser(resp.data);
    } catch (error) {
      ErrorHelper.handleError(error);
      throw error;
    }
  };

  const updateProfile = async (email: string, userDetails: DataAccountDetails) => {
    try {
      const resp = await updateProfileDetails(email, userDetails);
      dispatch({ type: actions.UPDATE_USER_PROFILE, payload: resp.data });
      setUser(resp.data);
    } catch (error) {
      ErrorHelper.handleError(error);
      throw error;
    }
  };

  const value = useMemo(
    () => ({
      authToken: getToken(),
      profile: getUser() || state.profile,
      signInUser: (email: string, password: string) => {
        return signIn(email, password);
      },
      resetLoggedUser: () => {
        dispatch({ type: actions.RESET_LOGGED_USER });
        logout();
      },
      updateAuthToken: (token: string) => {
        setToken(token);
        dispatch({ type: actions.UPDATE_AUTH_TOKEN, payload: token });
      },
      isUserLoggedIn: Boolean(getUser()) || Boolean(state.profile),
      updateProfileDetails: (email: string, userDetails: DataAccountDetails) => {
        return updateProfile(email, userDetails);
      },
      hasUserChosenGuest: state.hasUserChosenGuest,
      updateHasUserChosenGuest: (hasUserChosenGuest: boolean) => {
        dispatch({ type: actions.UPDATE_HAS_USER_CHOSEN_GUEST, payload: hasUserChosenGuest });
      },
    }),
    [state],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
