require('./src/styles/global.scss');
const { WrapRootElement } = require('./src/state/WrapRootElement');
const { WrapPageElement } = require('./src/state/WrapPageElement');
const axios = require('axios').default;

exports.onClientEntry = () => {
  axios.defaults.baseURL = '/api';
};
exports.wrapRootElement = WrapRootElement;
exports.wrapPageElement = WrapPageElement;
