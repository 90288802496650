import UserType from '../../models/UserType.model';

export enum OneWayStatus {
  WAITING_FOR_USER_TO_DELIVER = 'WAITING FOR USER TO DELIVER',
  IN_TRANSIT_AFTER_SHIPPED = 'IN TRANSIT AFTER SHIPPED',
  SHIPPING_PROCESS_DONE = 'SHIPPING PROCESS DONE', // WHEN DELIVERED IS DONE BY TRANSPORTER (home / store)
  IN_TRANSIT_TO_BE_RECEIVED = 'IN TRANSIT TO BE RECEIVED',
  RECEIVED_FROM_TRANSPORTER = 'RECEIVED FROM TRANSPORTER',
  WAITING_FOR_USER_TO_PICKUP = 'WAITING FOR USER TO PICKUP',
  DELIVERY_PROCESS_DONE = 'DELIVERY PROCESS DONE', // WHEN DELIVERED IS DONE BY STORE
}

export type OneWayState = {
  backofficeStatusTabUserType: UserType;
  backofficeStatusTab: OneWayStatus;
};

export const initialState: OneWayState = {
  backofficeStatusTabUserType: UserType.sender,
  backofficeStatusTab: OneWayStatus.WAITING_FOR_USER_TO_PICKUP,
};

export const actions = {
  UPDATE_BACKOFFICE_TAB_STATUS_USER_TYPE: 'UPDATE_BACKOFFICE_TAB_STATUS_USER_TYPE',
  UPDATE_BACKOFFICE_TAB_STATUS: 'UPDATE_BACKOFFICE_TAB_STATUS',
};

export const oneWayReducer = (
  state: OneWayState,
  action: { type: string; payload?: UserType | OneWayStatus },
) => {
  switch (action.type) {
    case actions.UPDATE_BACKOFFICE_TAB_STATUS_USER_TYPE:
      return {
        ...state,
        backofficeStatusTabUserType: action.payload,
      };
    case actions.UPDATE_BACKOFFICE_TAB_STATUS:
      return {
        ...state,
        backofficeStatusTab: action.payload,
      };
    default:
      return state;
  }
};
