import { Environment } from '../../models/environment.model';

export type AppState = {
  environment: Environment;
  redirect: boolean;
  redirectTarget: string;
  taxValue: number;
  areCookiesAllowed: boolean;
  shouldDisplayCookieModal: boolean;
};

export const initialState: AppState = {
  environment: Environment.DEV,
  redirect: true,
  redirectTarget: null,
  taxValue: 16,
  areCookiesAllowed: false,
  shouldDisplayCookieModal: true,
};

export const actions = {
  UPDATE_ENVIRONMENT: 'UPDATE_ENVIRONMENT',
  UPDATE_REDIRECT: 'UPDATE_REDIRECT',
  UPDATE_REDIRECT_TARGET: 'UPDATE_REDIRECT_TARGET',
  UPDATE_ARE_COOKIES_ALLOWED: 'UPDATE_ARE_COOKIES_ALLOWED',
  UPDATE_SHOULD_DISPLAY_COOKIES_MODAL: 'UPDATE_SHOULD_DISPLAY_COOKIES_MODAL',
};

export const appReducer = (
  state: AppState,
  action: { type: string; payload?: string | boolean | Environment },
) => {
  switch (action.type) {
    case actions.UPDATE_ENVIRONMENT:
      return {
        ...state,
        environment: action.payload,
      };
    case actions.UPDATE_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case actions.UPDATE_REDIRECT_TARGET:
      return {
        ...state,
        redirectTarget: action.payload,
      };
    case actions.UPDATE_ARE_COOKIES_ALLOWED:
      return {
        ...state,
        areCookiesAllowed: action.payload,
      };
    case actions.UPDATE_SHOULD_DISPLAY_COOKIES_MODAL:
      return {
        ...state,
        shouldDisplayCookieModal: action.payload,
      };
    default:
      return state;
  }
};
