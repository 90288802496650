import { AxiosInstance } from 'axios';

export default class StripeApi {
  readonly apiUrlPath = '/stripe';

  constructor(readonly httpClient: AxiosInstance) {}

  async changeDefaultPaymentMethod(customerID: string, paymentMethodID: string): Promise<any> {
    return this.httpClient.put(
      `${this.apiUrlPath}/customers/${customerID}/default/${paymentMethodID}`,
    );
  }

  async deletePaymentMethod(customerID: string, paymentMethodID: string): Promise<any> {
    return this.httpClient.put(
      `${this.apiUrlPath}/customers/${customerID}/detachPM/${paymentMethodID}`,
    );
  }

  async setupIntentSepaBankDetails(customerID: string): Promise<any> {
    return this.httpClient.post(`${this.apiUrlPath}/setup/sepa/${customerID}`);
  }

  async setupIntentSepaDone(customerID: string, paymentMethodID: string): Promise<any> {
    return this.httpClient.post(`${this.apiUrlPath}/setup/sepa/done`, {
      customerID,
      paymentMethodID,
    });
  }

  async setupPaymentIntent(
    customerID: string,
    paymentMethodID: string,
    colisportID: string,
    amount: number,
    currency: string,
  ): Promise<any> {
    return this.httpClient.post(`${this.apiUrlPath}/sepa`, {
      customerID,
      paymentMethodID,
      colisportID,
      amount,
      currency,
    });
  }

  async updatePaymentIntent(data: any): Promise<void> {
    return this.httpClient.put(`${this.apiUrlPath}/paymentIntent`, { ...data });
  }

  async createPaymentLink(colisportID: string, amount: number, currency: string): Promise<any> {
    return this.httpClient.post(`${this.apiUrlPath}/createPaymentLink`, {
      colisportID,
      amount,
      currency,
    });
  }
}
