import { AxiosInstance, AxiosResponse } from 'axios';
import DataAddress from '../../components/Account/Addresses/data-address.model';
import ColisportApiResource from './colisport-api-resource.model';

export default class AddressApi implements ColisportApiResource {
  readonly apiUrlPath = 'address';

  constructor(readonly httpClient: AxiosInstance) {}

  getAddresses(email: string): Promise<AxiosResponse<DataAddress[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/${email}`);
  }

  newAddress(addressDetails: DataAddress): Promise<void> {
    return this.httpClient.post(`${this.apiUrlPath}/newaddress`, addressDetails);
  }

  updateAddressDetails(id: string, addressDetails: DataAddress): Promise<void> {
    return this.httpClient.put(`${this.apiUrlPath}/update/${id}`, addressDetails);
  }

  deleteAddress(addressId: string): Promise<void> {
    return this.httpClient.delete(`${this.apiUrlPath}/delete/${addressId}`);
  }
}
