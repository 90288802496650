import { WrapPageElementBrowserArgs } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../contexts/AppProvider';
import ErrorHelper from '../helpers/ErrorHelper';
import { useColisportApi } from '../hooks/useColisportApi';

export const WrapPageElement: React.FC<WrapPageElementBrowserArgs> = ({ element }) => {
  const { healthApi } = useColisportApi();
  const { updateEnvironment } = useContext(AppContext);
  useEffect(() => {
    const getEnvironment = async () => {
      const { data } = await healthApi.getEnvironment();
      updateEnvironment(data);
    };
    getEnvironment().catch((error) => {
      ErrorHelper.handleError(error);
    });
    // eslint-disable-next-line
  }, []);

  return <div>{element}</div>;
};
