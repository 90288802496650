import { UserProfile } from 'models/user-profile.model';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = (): UserProfile =>
  isBrowser() && window.sessionStorage.getItem('colisportUser')
    ? (JSON.parse(window.sessionStorage.getItem('colisportUser')) as UserProfile)
    : undefined;

export const setUser = (user: UserProfile | string) =>
  window.sessionStorage.setItem('colisportUser', JSON.stringify(user));

export const getToken = (): string =>
  isBrowser() && window.sessionStorage.getItem('authToken')
    ? window.sessionStorage.getItem('authToken')
    : '';

export const setToken = (token: string) => window.sessionStorage.setItem('authToken', token);

export const logout = () => {
  setUser('');
  setToken('');
};
