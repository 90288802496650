import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';

export default class NewsletterApi implements ColisportApiResource {
  readonly apiUrlPath = 'newsletter';

  constructor(readonly httpClient: AxiosInstance) {}

  createSubscription(email: string): Promise<AxiosResponse<void>> {
    return this.httpClient.post(`${this.apiUrlPath}`, { email });
  }
}
