import { UserProfile } from 'models/user-profile.model';

export type UserState = {
  authToken: string;
  userId: string;
  profile: UserProfile;
  hasUserChosenGuest: boolean;
};

export const initialState: UserState = {
  authToken: '',
  userId: '',
  profile: undefined,
  hasUserChosenGuest: false,
};

export const actions = {
  UPDATE_AUTH_TOKEN: 'UPDATE_AUTH_TOKEN',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  RESET_LOGGED_USER: 'RESET_LOGGED_USER',
  UPDATE_HAS_USER_CHOSEN_GUEST: 'UPDATE_HAS_USER_CHOSEN_GUEST',
};

export const userReducer = (
  state: UserState,
  action: { type: string; payload?: UserProfile | string | boolean },
) => {
  switch (action.type) {
    case actions.UPDATE_USER_PROFILE:
      // eslint-disable-next-line no-case-declarations
      const user = action.payload as UserProfile;
      return {
        ...state,
        profile: { ...user },
        userId: user._id,
      };
    case actions.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case actions.RESET_LOGGED_USER:
      return {
        ...state,
        authToken: '',
        userId: '',
        profile: undefined,
        hasUserChosenGuest: false,
      };
    case actions.UPDATE_HAS_USER_CHOSEN_GUEST:
      return {
        ...state,
        hasUserChosenGuest: action.payload,
      };
    default:
      return state;
  }
};
