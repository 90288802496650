import { WrapRootElementBrowserArgs } from 'gatsby';
import React from 'react';

import AppProvider from '../contexts/AppProvider';
import StepsFormProvider from '../contexts/StepsFormProvider';
import UserProvider from '../contexts/UserProvider';
import OneWayProvider from '../contexts/OneWay';

export const WrapRootElement: React.FC<WrapRootElementBrowserArgs> = ({ element }) => {
  return (
    <AppProvider>
      <OneWayProvider>
        <UserProvider>
          <StepsFormProvider>{element}</StepsFormProvider>
        </UserProvider>
      </OneWayProvider>
    </AppProvider>
  );
};
