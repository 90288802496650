import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import { CountryNotSupported } from '../../models/country-not-supported.model';
import { CancelOrder } from '../../models/cancel-order.model';

export default class EmailApi implements ColisportApiResource {
  readonly apiUrlPath = 'email';

  constructor(readonly httpClient: AxiosInstance) {}

  sendCountryNotSupported(email: string, data: CountryNotSupported): Promise<AxiosResponse> {
    return this.httpClient.post(`${this.apiUrlPath}/countryNotSupported`, data, {
      params: {
        email,
      },
    });
  }

  saveEmailNewsletter(email: string): Promise<AxiosResponse> {
    return this.httpClient.post(`${this.apiUrlPath}/${email}`, {
      params: {
        email,
      },
    });
  }

  sendOrderCancelation(data: CancelOrder): Promise<AxiosResponse> {
    return this.httpClient.post(`${this.apiUrlPath}/cancelOrder`, data);
  }

  async resendShipmentEmail(colisportID: string, emailType: string): Promise<AxiosResponse<void>> {
    return this.httpClient.post(`${this.apiUrlPath}/resendShipmentEmail`, {
      colisportID,
      emailType,
    });
  }
}
