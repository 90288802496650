import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import { OfferOptionsRequestApi } from '../../models/offer-options-request-api.model';
import { DeliveryPrice } from '../../models/offer-option.model';

export default class OfferApi implements ColisportApiResource {
  readonly apiUrlPath = 'offer';

  constructor(readonly httpClient: AxiosInstance) {}

  getOffers(data: OfferOptionsRequestApi): Promise<AxiosResponse<DeliveryPrice[]>> {
    return this.httpClient.post(`${this.apiUrlPath}/calculate`, data);
  }

  isValidDate(): Promise<AxiosResponse<boolean>> {
    return this.httpClient.get(`${this.apiUrlPath}/isBefore17Pm`);
  }
}
