import axios, { AxiosResponse } from 'axios';
import DataAccountDetails from 'models/data-account-details.model';
import DataEmail from 'models/data-email.model';
import DataPasswordUpdate from 'models/data-password-update.model';
import { UserProfile } from 'models/user-profile.model';

const apiUrlPath = 'user';

export const getUserProfile = (): Promise<AxiosResponse<UserProfile>> => {
  return axios.get(`${apiUrlPath}/profile`);
};

export const updateProfileDetails = (
  email: string,
  userDetails: DataAccountDetails,
): Promise<AxiosResponse<UserProfile>> => {
  return axios.patch(`${apiUrlPath}/update/${email}`, userDetails);
};

export const updatePasswordDetails = (
  email: string,
  passwordDetails: DataPasswordUpdate,
  isSecurity: boolean,
): Promise<AxiosResponse<UserProfile>> => {
  const body = { ...passwordDetails, isSecurity };
  return axios.patch(`${apiUrlPath}/update/${email}`, body);
};

export const emailCheck = (
  userData: DataEmail,
): Promise<AxiosResponse<{ data: { email: string } }>> => {
  return axios.get(`${apiUrlPath}/${userData.email}`);
};
