import { AxiosInstance, AxiosResponse } from 'axios';
import AgencyQuery from '../../components/Account/Addresses/agency-query.model';
import Agency from '../../models/agency.model';
import ColisportApiResource from './colisport-api-resource.model';

export default class AgencyApi implements ColisportApiResource {
  readonly apiUrlPath = 'agency';

  constructor(readonly httpClient: AxiosInstance) {}

  getAgencies(agencyDetails: AgencyQuery): Promise<AxiosResponse<Agency[]>> {
    return this.httpClient.post(`${this.apiUrlPath}/available`, agencyDetails);
  }
}
