import React, { useMemo, useReducer } from 'react';
import { actions, oneWayReducer, initialState, OneWayStatus } from '../state/oneway/reducers';
import UserType from '../models/UserType.model';

export type OneWayContextType = {
  backofficeStatusTabUserType: UserType;
  backofficeStatusTab: OneWayStatus;
  updateBackofficeStatusTabUserType: (userType: UserType) => void;
  updateBackofficeStatusTab: (status: OneWayStatus) => void;
};

const defaultOneWayContextValues: OneWayContextType = {
  backofficeStatusTabUserType: initialState.backofficeStatusTabUserType,
  backofficeStatusTab: initialState.backofficeStatusTab,
  updateBackofficeStatusTabUserType: () => {},
  updateBackofficeStatusTab: () => {},
};

export const OneWayContext = React.createContext<OneWayContextType>(defaultOneWayContextValues);

const OneWayProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(oneWayReducer, initialState);

  const value = useMemo(
    () => ({
      backofficeStatusTabUserType: state.backofficeStatusTabUserType,
      backofficeStatusTab: state.backofficeStatusTab,
      updateBackofficeStatusTabUserType: (userType: UserType) => {
        dispatch({ type: actions.UPDATE_BACKOFFICE_TAB_STATUS_USER_TYPE, payload: userType });
      },
      updateBackofficeStatusTab: (status: OneWayStatus) => {
        dispatch({ type: actions.UPDATE_BACKOFFICE_TAB_STATUS, payload: status });
      },
    }),
    [state],
  );

  return <OneWayContext.Provider value={value}>{children}</OneWayContext.Provider>;
};

export default OneWayProvider;
