import React, { useMemo, useReducer } from 'react';

import { Environment } from '../models/environment.model';
import { actions, appReducer, initialState } from '../state/app/reducers';

export type AppContextType = {
  environment: Environment;
  redirect: boolean;
  redirectTarget: string;
  taxValue: number;
  areCookiesAllowed: boolean;
  shouldDisplayCookieModal: boolean;
  updateEnvironment: (environment: Environment) => void;
  updateRedirect: (redirect: boolean) => void;
  updateRedirectTarget: (redirectTarget: string) => void;
  updateAreCookiesAllowed: (areCookiesAllowed: boolean) => void;
  updateShouldDisplayCookieModal: (shouldDisplayCookieModal: boolean) => void;
};

const defaultAppContextValues: AppContextType = {
  environment: initialState.environment,
  redirect: initialState.redirect,
  redirectTarget: initialState.redirectTarget,
  taxValue: initialState.taxValue,
  areCookiesAllowed: initialState.areCookiesAllowed,
  shouldDisplayCookieModal: initialState.shouldDisplayCookieModal,
  updateEnvironment: () => {},
  updateRedirect: () => {},
  updateRedirectTarget: () => {},
  updateAreCookiesAllowed: () => {},
  updateShouldDisplayCookieModal: () => {},
};

export const AppContext = React.createContext<AppContextType>(defaultAppContextValues);

const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const value = useMemo(
    () => ({
      environment: state.environment,
      redirect: state.redirect,
      redirectTarget: state.redirectTarget,
      taxValue: state.taxValue,
      areCookiesAllowed: state.areCookiesAllowed,
      shouldDisplayCookieModal: state.shouldDisplayCookieModal,
      updateEnvironment: (environment: Environment) => {
        dispatch({ type: actions.UPDATE_ENVIRONMENT, payload: environment });
      },
      updateRedirect: (redirect: boolean) => {
        dispatch({ type: actions.UPDATE_REDIRECT, payload: redirect });
      },
      updateRedirectTarget: (redirectTarget: string) => {
        dispatch({ type: actions.UPDATE_REDIRECT_TARGET, payload: redirectTarget });
      },
      updateAreCookiesAllowed: (bool: boolean) => {
        dispatch({ type: actions.UPDATE_ARE_COOKIES_ALLOWED, payload: bool });
      },
      updateShouldDisplayCookieModal: (bool: boolean) => {
        dispatch({ type: actions.UPDATE_SHOULD_DISPLAY_COOKIES_MODAL, payload: bool });
      },
    }),
    [state],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
