import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import Requests from '../../models/requests.model';
import { FilterRequests } from '../../models/filter-request.model';

export default class RequestsApi implements ColisportApiResource {
  readonly apiUrlPath = 'requests';

  constructor(readonly httpClient: AxiosInstance) {}

  getAllRequests(): Promise<AxiosResponse<Requests[]>> {
    return this.httpClient.get(`${this.apiUrlPath}`);
  }

  async getRequestsFiltered(query?: string): Promise<AxiosResponse<FilterRequests>> {
    return this.httpClient.get(`${this.apiUrlPath}/filters${query !== '' ? `?${query}` : ''}`, {
      params: `filters?${query}`,
    });
  }

  async getRequestsById(requestId: string): Promise<AxiosResponse<Requests>> {
    return this.httpClient.get(`${this.apiUrlPath}/${requestId}`);
  }

  async update(requestID: string, data: Partial<Requests>): Promise<AxiosResponse<Requests>> {
    return this.httpClient.put(`${this.apiUrlPath}/update/${requestID}`, data);
  }
}
